// 纳税人识别号正则
const _identify = (text) => {
    let identify = /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/;
    if (!identify.test(text)) {
        return false
    } else {
        return true
    }
}

// 手机号正则
const _phone = (phone) => {
    if (phone.length != 11) {
      return false;
    } else {
      let regtel = /^[0-9]*$/;
      if (regtel.test(phone)) {
        return true;
      }
      return false;
    }
    // let regTel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    // if (!regTel.test(phone)) {
    //     return false
    // } else {
    //     return true
    // }
}

// 身份证正则
const _isCardNo = (cardNo) => {
    let regCardNo = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    if (!regCardNo.test(cardNo)) {
        return false
    } else {
        return true
    }
}

// 只能输入整数或小数的正则
export const _integer = (num) => {
    let int = /^\d+(\.\d{0,2})?$/;
    if (!int.test(num)) {
        return false
    } else {
        return true
    }
}

//数字
export const _isAllNumber = (payload) => {
    const reg = /^[0-9]*$/
    return reg.test(payload)
}

//数字和英文
export const _isNumberOrLetter = (payload) => {
    const reg = /^[A-Za-z0-9]+$/
    return reg.test(payload)
}

//补齐小数点  补齐至后面两位
export const _polishing = (num) => {
    let radixPoint = String(num).indexOf('.') + 1
    let radixNum = ''
    if (radixPoint > 0) {
        if (num.length >= 4) {
            radixNum = num
        } else if (num.length == 3) {
            radixNum = `${num}0`
        } else if (num.length == 2 || num.length == 1) {
            radixNum = `${num}00`
        }
    } else {
        radixNum = `${num}.00`
    }
    return radixNum
}

// 判断是否输入小数点  最多支持整数后两位
export const _radix = (num) => {
    let radixPoint = String(num).indexOf('.') + 1
    let radixNum = ''
    if (radixPoint > 0) {
        if (num.length >= 4) {
            radixNum = num
        } else if (num.length == 3) {
            radixNum = `${num}0`
        } else if (num.length == 2) {
            radixNum = `${num}00`
        }
    } else {
        radixNum = `${num}.00`
    }
    return radixNum
}

// 补齐发票费率后面的0
const _addZero = (rateNum) => {
    let rate = ''
    if (rateNum.toString().length == 4) {
        rate = rateNum
    } else if (rateNum.toString().length == 3) {
        rate = `${rateNum}0`
    } else if (rateNum.toString().length == 2) {
        rate = `${rateNum}00`
    } else if (rateNum.toString().length == 1) {
        rate = `${rateNum}.00`
    }
    return rate
}

// 车牌号正则
const _isCarNum = (CarNum) => {
    let creg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4,5}[A-Z0-9挂学警港澳]{1}$/
    if (!creg.test(CarNum)) {
        return false
    } else {
        return true
    }
}

// 是否存在小数点
const _isDecimals = (num) => {
    let reg = /^(([^0][0-9]+|0)\.([0-9]{1,2}))$/
    if (!reg.test(num)) {
        return false
    } else {
        return true
    }
}

// 弱密码校验
const _weakPassword = (password) => {
    //是否连续3次出现重复字符
    const regRepeat = /([0-9a-zA-Z])\1{2}/
    //是否包含数字
    const regDisgit = /(?=.*[0-9])/
    //是否包含大小写
    const regLetter = /(?=.*[A-Z])(?=.*[a-z])/
    if (password.length >= 6 && !regRepeat.test(password) && regLetter.test(password) && regDigit.test(password)) {
        return true
    } else {
        return false
    }
}

export default {
    _addZero,
    _identify,
    _phone,
    _isCardNo,
    _integer,
    _radix,
    _isCarNum,
    _isDecimals,
    _weakPassword
}